import React from 'react';

export default function DatenschutzPage() {
  return (
    <>
      <h1>404</h1>
      <h2>Page not found</h2>
    </>
  );
}
